.logoContainer {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 5%;
  img {
    width: auto;
    max-height: 65px;
    justify-content: center;
    margin-top: 2%;
  }
}
.sideActionsContainer {
  margin-top: 20px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #373737;
  margin-right: 10px;
  border-radius: 0px 5px 5px 0px;


  .sideActionRow {
    padding: 5px;
  }
}
.selectFrameContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  border-bottom: 1px solid #b5bbc1;
  width: 100%;
  .selectFrame {
    padding: 10px;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
  }
}

.sideCalendarContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-top: 5%;
  border-bottom: 1px solid #b5bbc1;
  width: 100%;
  .sideCalendar {
    padding: 10px;
    // margin-bottom: 20px;
  }
}

.stockTimeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  padding: 6px;
  flex-direction: column;
}

.businessRules {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}
hr {
  width: 100%;
}

:global .ant-layout.ant-layout-has-sider {
  background-color: white;
}

:global .ant-layout-sider.ant-layout-sider-dark {
  width: 100%;
}
:global .ant-layout-sider {
  width: 290px !important;
  //padding:0
}

:global .ant-layout-sider-children {
  width: 100%;
}
