.createOrderContainer {
  display: flex;
  background-color: #07191d;
  max-width: 100%;
  height: 620px;
  max-height: 650px;
  align-items: center;
  border-radius: 10px;
  flex-wrap: wrap;

  .slider-prefix-cls {
    height: 250px;
  }
  .ant-slider-rail {
    height: 250px !important;
  }
  .resultSpan {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    border-radius: 10px;
    font-size: 13px;
    background-color: #5fa046;
    margin-top: 10%;
    color: #fff;
    font-family: helvetica;
  }

  .bottomWrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .bottomContainer {
      display: flex;
    }
  }
  .submitBtnContainer {
    display: flex;
    align-items: center;
  }
}

.inputOverride::placeholder {
  font-size: 13px !important;
  text-align: center !important;
  font-weight: normal !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  display: flex;
  flex-direction: column;
  .variableContainer {
    display: flex;
    align-items: center;
    margin-top: 5%;
    flex-direction: row;
  }

  .orderResume {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-left: 2%;
  }
}

.pregaoSpan {
  color: white;
  font-weight: bold;
  margin-left: 6px;
  margin-top: 7px;
  font-family: helvetica;
  white-space: pre;
}

.inputOverride {
  input {
    font-weight: bold;
    text-align: center;
  }

  .ant-input-number-handler-wrap {
    border-radius: 5px;
  }
}
