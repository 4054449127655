.frameMainContainer {
  background-color: #07191d !important;
  height: 100%;
  min-height: 200px;
  width: 100%;
  padding: 10px;
  min-width: 500px;
}

.frameTop {
  display: flex;
  padding: 20px;
  border-bottom: 1px solid grey;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.containerButton {
  display: flex;
  flex-direction: row;
}

.frameSpan {
  font-size: 16px;
  color: white;
  font-weight: bold;
  font-family: "helvetica";
  margin-right: 1%;
  display: flex;
  align-items: center;
}

.selectActual {
  border: 1px solid white;
  border-radius: 18px;
  display: flex;
}

:global .recharts-layer {
  background-color: red;
}

.frameBodyContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .lineChartContainer {
    display: flex;
    justify-content: center;
    margin-top: 2%;
    margin-right: 2%;
    margin-left: 4%;
  }
  .frameTable {
    display: flex;
    justify-content: center;
    margin-top: 2%;
    width: 100%;
  }
}