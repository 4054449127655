.activeAlertsContainer {
  background-color: #07191d;
  max-width: 90%;
  height: 620px;
  margin-left: 2%;
  max-height: 620px;
  padding: 20px;
  border-radius: 10px;
  overflow-y: auto;

  .alertRowContainer {
    display: flex;
    color: white;
    flex-direction: row;
    height: 6%;
    text-align: center;
    margin-top: 4%;
    border-bottom: 1px solid #fff;

    .alertRowItem {
      display: flex;
      align-items: center;
      justify-content: space-around;
      color: white;
      width: 50000%;
      height: 2%;
    }

    .indicateSpan {
      color: white;
      border: 1px solid #66bc46;
      border-radius: 10px;
      width: 60px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 20px;
      font-family: helvetica;
    }
  }
}
