.rowContainer {
  display: flex;
  padding: 10px;

  .columnContainer {
    width: 100%;

    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 40px;
      height: 40px;
      margin-left: 10%;
    }

    span {
      text-align: left;
      font-size: 12px;
      font-family: helvetica;
      color: #FFFFFF;
      font-style: normal;
      font-size: 17px;
      line-height: 19px;
    }

    button {
      background: #7BB957;
      width: 150px;
      height: 50px;
      border-radius: 5px;
      border-width: 0;
  
      text-transform: uppercase;
      color: #FFFFFF;
      font-family: "helvetica";
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 15px;

      &:hover {
        cursor: pointer;
      }
    }
  }
  
}
