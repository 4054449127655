.icons {
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.actionsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}