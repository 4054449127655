
.carouselStyle{
    height: '160px';
    color: '#fff';
    line-height: '160px';
    text-align: 'center';
    background-color: '#364d79';
}



  .average{
    font-size: 8px;
    color:#a5ab97;
    float:top;
    margin-bottom: 50px !important;
  }

  .bottomProgress{
    font-size: 14px;
    color: white;
  }