.montarInputComValor{
    width: 90px;
    text-align: center;
    height: 25px;
    margin-left: 4px;
    margin-right: 4px;
    background-color: #5e5e5e;
    margin-bottom: 2px;
}

.montarInputComValorBackgroundOff{
    width: 27px;
    text-align: center;
    height: 25px;
    background-color: transparent;
    border: none;
    margin-left: 4px;
    margin-right: 4px;
    font-weight: bold;
    margin-top: 2px;
}

.montarInputSemValor{
    width: 85px;
    text-align: center;
    height: 25px;
    margin-left: 4px;
    margin-right: 4px;
    background-color: #5e5e5e;
    margin-top: 2px;
}

.montarSpan{
    width: 25px;
    text-align: center;
    background-color: white;
    color: black;
    margin-left: 4px;
    margin-right: 4px;
    padding: 4px 9px;
}

.montarSpanNumber{
    width: 25px;
    text-align: center;
    background-color: #5e5e5e;
    color: white;
    margin-left: 4px;
    margin-right: 4px;
    padding: 4px 9px;
}
.montarSpanDefault{
    margin-left: 1px;
    margin-right: 1px;
}