.ant-layout-header {
  border-radius: 15px;
  background: #181818;
  display: flex;
  flex-direction: row;
  margin-left: 8px;
  align-items: center;
  padding: 10px;
  /* padding-left: 25px; */
}

.img-header {
  margin-left: 10px;
  margin-bottom: 5px;
  height: 25px;
  width: 25px;
}

p {
  color: #FFFFFF;
  margin-top: 12px;
  font-weight: 700;
  font-size: 17px;
}
