.swingDataRow {
  display: flex;
  color: white;
  flex-direction: row;
  height: 6%;
  text-align: center;
  margin-top: 4%;
  border-bottom: 1px solid #fff;
  .swingRowItem {
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: white;
    width: 100%;
    height: 2%;
  }
}

.emptyRow {
  height: 6%;
  width: 6%;
}

.divVariableRow{
  margin-bottom: 10px;
}