.quickSimulationMain {
  background-color: #07191d;
  height: 100%;
  min-height: 200px;
  width: 100%;
  padding: 20px;
  border-radius: 10px;

  .quickSimulationTop {
    display: flex;
    padding: 20px;
    border-bottom: 1px solid grey;
    justify-content: space-between;
  }

  .quickSimulationSpan {
    font-size: 16px;
    color: white;
    font-weight: bold;
    font-family: "helvetica";
  }
  .quickSimulationBody {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 2%;
  }
  .quickResult {
    height: 30px;
    width: 250px;
    border-bottom: 2px solid #65b847;
    margin-top: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
}

.inputStyle {
  background-color: transparent !important;
  width: 250px;
  border: none;
  border-bottom: 2px solid white;
  transition: all 1s;
  color: white;
  outline: 0 !important;

  &:hover {
    box-shadow: none;
    border-bottom: 2px solid #57b846;
    transition: all 1s;
  }
}
