.frameMainContainer {
  height: 100%;
  min-height: 200px;
  width: 100%;
  padding: 10px;
  min-width: 500px;
  margin-left: 10px;

  .selectMonthContainer {
    margin-top: 50px;
    justify-content: space-between;
    padding: 5px;
    border: 1.3px solid #7A7A7A;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    
    span {
      color: #fff;
      font-size: 17px;
      text-align: center;
      margin-left: 15px;
    }
  }

  .selectMonthContainerValue {
    margin-top: 10px;
    justify-content: space-between;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    
    span {
      color: #fff;
      font-size: 12px;
      text-align: center;
      margin-left: 15px;
    }
  }

  .frameBodyContainer {
    display: flex;
    margin-top: 2%;
  } 
}

.container {
  width: 100%;
  height: 300px;
  margin-top: 50px;
  margin-left: 10px;
  border-radius: 8px;
  background: #282929;
  display: flex;
  justify-content: space-around;

  .container_equation {
    width: 90%;

    .info {
      background: #1D1D1D;
      height: 125px;
      max-height: 250px;
      overflow-y: auto;
      margin: 20px;
      padding-left: 10px;
      padding-right: 10px;
      
      div {
        width: auto;
        height: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 15px;
        padding-right: 15px;
        
        p {
          text-align: left;
          font-size: 13px;
        }
      }

    }

    div {
      height: 130px;
      color: #fff;
      border-radius: 8px;
      justify-content: center;
      text-align: center;
    }

    [aria-disabled=true] {
      background: #1D1D1D;
      padding-top: 20px;
      margin: 20px;
    }
  }

  main {
    width: 50%;
  }

  .container_fee {
    height: 127px;
    background: #1D1D1D;
    margin-top: 20px;
    margin-right: 20px;
    border-radius: 8px;
    align-items: center;
    text-align: center;
    padding: 10px;

    strong {
      text-align: center;
      font-family: "helvetica";
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 20px;
      text-align: center;
      color: #FFFFFF;
    }

    .container_fee_info {
      display: flex;
      padding-left: 10%;
      padding-bottom: 5%;
      padding-right: 10%;

      .total_volume {
        margin-top: 17px;
      }
      
      div {
        color: #fff;
        margin-top: 10px;
      }

      h4 {
        font-family: "helvetica";
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 22px;
        text-align: center;
        margin-top: 12px;
        color: #FFFFFF;
      }

      h4:last-child {
        font-size: 27px;
        margin-top: 20px;
      }
    }
  }
}

.container_button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 10px;

  button {
    border-radius: 75px;
    border-width: 0;
    width: 130px;
    height: 30px;

    text-transform: uppercase;
    font-family: "helvetica";
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 13px;
    text-align: center;
    color: #fff;
    background: #1E4209;

    cursor: pointer;
  }

  button:disabled {
    background: #2B2B2B;
    color: #A8A8A8;

    &:hover {
      cursor: default;
    }
  }

  div {
    button:first-child {
      background: #F1F1F1;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      color: #1D1D1D;
      margin-right: 8px;
    }
    button:disabled {
      background: #2B2B2B;
      color: #A8A8A8;
  
      &:hover {
        cursor: default;
      }
    }
  }

  button:first-child {
    background: #F1F1F1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #1D1D1D;
    margin-right: 8px;
  }
}

.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
}

.modal_wrapper {
  position: fixed;
  top: 60px;
  left: 120px;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal {
  background: #1D1D1D;
  z-index: 100;
  position: relative;
  margin:20px auto;
  border-radius: 3px;
  max-width: 800px;
  width: 400px;
  padding: 8px;
  border-radius: 4px;

  h3 {
    color: #F1F1F1;
    text-align: center;
    font-size: 20px;
    line-height: 22px;
  }

  span {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 15px;
    line-height: 17px;
    margin-bottom: 8px;
  }

  .fee {
    display: flex;
    flex-direction: column;

    textarea {
      resize: none;
      width: 100%;
      height: 100px;
      padding: 7px;
      font-size: 15px;
      background: #2B2B2B;
      border: 1px solid #A9A9A9;
      box-sizing: border-box;
      color: #FFFFFF;
      font-size: 15px;
      line-height: 17px;
      font-weight: 500;
    }

    input {
      background: #2B2B2B;
      color: #FFFFFF;
      padding: 8px;
      // border: 1px solid #A9A9A9;
      box-sizing: border-box;
      border-radius: 5px;
      margin-bottom: 8px;
    }
  }

  .buttons {
    justify-content: space-between;
    padding: 10px;
    display: flex;
    
    button {
      border-radius: 10px;
      color: #F1F1F1;
      border: none;
      width: 84px;
      height: 30px;
      background: #1E4209;
      font-weight: 600;
      font-size: 17px;
      cursor: pointer;
    }

    button:first-child {
      background: red;
    }
  }
}