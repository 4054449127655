.activeTop {
  display: flex;
  color: white;
  flex-direction: row;
  height: auto;
  text-align: center;
  margin-top: 4%;
  border-bottom: 1px solid #fff;
  font-size: 15px;
  margin-bottom: 2%;

  .itemsRow {
    display: flex;
    width: 100%;
    margin-top: -2%;
  }
  .pendentHeaderTitle {
    width: 100%;
    height: 2%;
    font-family: helvetica;
  }
}
.anticon .anticon-down .ant-select-suffix {
  color: white;
}

.ant-select-selector {
  background-color: #66bc46 !important;
}
