.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .circle {
    background-color: white;
    border-radius: 90px;
    height: 150px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .message {
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: white;
    font-family: "helvetica";
    margin-top: 2%;
  }
}
