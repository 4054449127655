.tableContainer {
  flex-direction: column;
  width: 100%;

  .topMonthsContainer {
    border-bottom: 1px solid #eee;
    width: 100%;
    padding: 15px;

    .topMonthsRow {
      display: flex;
      text-align: center;
      justify-content: space-between;
    }
    .monthItem {
      color: #66bc46;
      font-weight: bold;
      width: 8.142857142857142%;
      font-size: 18px;
      display: flex;
      justify-content: center;
    }
  }

  .resultContainer {
    border-bottom: 1px solid #eee;
    padding: 0.8%;
    display: flex;
    .resultSpan {
      min-width: 64px;
      color: white;
      font-size: 16px;
      font-family: helvetica;
    }
  }
  .cbotContainer {
    border-bottom: 1px solid #eee;
    padding: 0.8%;
    display: flex;

    .cbotSpan {
      min-width: 64px;
      color: #ea6f24;
      font-size: 16px;
      font-family: helvetica;
    }
  }

  .basisContainer {
    display: flex;
    border-bottom: 1px solid #eee;
    padding: 0.8%;
    .basisSpan {
      min-width: 64px;
      color: #0072bc;
      font-size: 16px;
      font-family: helvetica;
    }
  }

  .dolarContainer {
    border-bottom: 1px solid #eee;
    display: flex;
    padding: 0.8%;

    .dolarSpan {
      min-width: 64px;
      color: #66bc46;
      font-size: 16px;
      font-family: helvetica;
    }
  }

  .spotContainer {
    border-bottom: 1px solid #eee;
    padding: 0.8%;
    display: flex;

    .spotSpan {
      min-width: 64px;
      color: white;
      font-size: 16px;
      font-family: helvetica;
    }
  }
}

.unityDiv {
  width: 0.5%;
  align-items: center;
  margin-right: 2%;
}

.tableRow {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: space-around;
}

.squareUnity {
  align-items: center;
  margin-left: -2%;
  display: flex;
}

.squareMonth {
  width: 5%;
  align-items: center;
  margin-left: -2%;
  margin-right: 2%;
}

.tableWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.imgSpot {
  width: 27px;
  height: 27px;
  margin-left: 8px;

  &:hover {
    cursor: pointer;
  }
}

.buttonFilter {
  display: flex;
  flex-direction: row;
  justify-content: end;
  border-bottom: 1px solid #eee;
}

.buttonContainer {
  border: 1px solid white;
  border-radius: 18px;
  display: flex;
  margin-bottom: 10px;
}

.tooltipContainer {
  display: flex;
  padding: 8px;
  border-radius: 8px;
  flex-direction: column;

  background: #1B1B1B;

  .tooltipContent {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }

  .tooltipCashImg {
    height: 27px;
    width: 27px;
    margin-right: 10px;
  }

  img {
    height: 27px;
    width: 27px;
    margin-left: 10px;
  }
  div {
    display: flex;
    flex-direction: column;

    span {
      font-size: 16px;
      color: #eee;
      font-family: "helvetica";
    }

    P {
      font-size: 16px;
      color: #eee;
      font-family: "helvetica";
      text-transform: uppercase;
    }
    
    strong {
      font-size: 13px;
      color: #eee;
      font-family: "helvetica";
      text-transform: uppercase;
    }
  }

  .formSection {
    gap: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;

    input {
      background: #727272;
      border-radius: 5px;
      width: 164px;
      height: 36px;
      border-width: 0;

      color: #FFFFFF;
      text-align: center;
      font-size: 15px;
      line-height: 21px;

    }
    input:disabled {
      background: #2B2B2B;
      color: #A8A8A8;
    }

    p {
      font-family: "helvetica";
      font-size: 10px;
      margin-top: 5px;
      line-height: 15px;
      margin-left: 5px;
      color: #FFFFFF;
    }

    button {
      width: 156px;
      height: 36px;

      border-width: 0;

      background: #7BB957;
      border-radius: 5px;
      color: #FFFFFF;

      font-family: "helvetica";
      font-weight: bold;
      font-size: 15px;
      line-height: 21px;
      text-align: center;
     
      &:hover {
        cursor: pointer;
      }
    }
    .cancel {
      background: #424441;
    }

  }
}



.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
}

.modal_wrapper {
  position: fixed;
  top: 60px;
  left: 120px;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal {
  background: #1D1D1D;
  z-index: 100;
  position: relative;
  margin: 20px auto;
  border-radius: 3px;
  max-width: 600px;
  width: 400px;
  padding: 8px;
  border-radius: 4px;

  h3 {
    color: #F1F1F1;
    text-align: center;
    font-size: 20px;
    line-height: 22px;
  }

  .buttons {
    justify-content: space-around;
    flex-direction: row;
    padding: 10px;
    display: flex;

    button {
      cursor: pointer;
      border-radius: 10px;
      color: #F1F1F1;
      border: none;
      width: 84px;
      height: 30px;
      background: #1E4209;
      font-weight: 600;
      font-size: 17px;
    }

    button:first-child {
      background: red;
    }
  }
}