.popoverContainer {
  // width: 470px;
  padding: 15px;
  border-radius: 3px;
  background: #000;
}

.modalAlert {
  // max-width: 470px;
  max-height: 300px;
  padding: 15px;
  border-radius: 3px;
  background: #000;
  margin-bottom: 17px;
  border-bottom: 8px;
  overflow-y: scroll;
  overflow: auto;
  border-bottom-color: 'transparent ';
}

.buttonContainer {
  width: 100%;
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
}

.customized_tooltip_content {
  width: 200px;
  height: 50px;
  border-radius: 8px;
  background: #000;
}