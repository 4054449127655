.mainWrapper {
  width: 100%;
}

.progressWrapper {
  max-width: 100%;
  width: 250px;

  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2);
}
