.ant-btn-primary {
  background-color: #66bc46 !important;
  border: none;
}

html {
  scroll-behavior: smooth;
}

.ant-switch-checked {
  background-color: #66bc46 !important;
}

.ant-switch{
  background-color: #74787e
}

.ant-btn:hover {
  // border: #66bc46;
  background: #fff;
  border-color: #66bc46;
}

* {
  animation: all 1s;
}

.ant-spin-dot-item {
  background-color: #5fa046 !important
}