.mainContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  .expressionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-color: rgb(206, 206, 206);
    width: 100%;
    height: 100px;
    margin-top: -8px;
    color: black;
    .spanWrapper{
      color: black;
      max-width: 250px;
      font-weight: 600;
      font-size: 13px;
    }
  }

  .contractInfoContainer {
    border-bottom: 1px solid #b5bbc1;
    padding: 20px;
    width: 100%;
  }
  .contractInfoInsider {
    display: flex;
    flex-direction: column;
    margin-top: 15%;

    .contractInfoDiv {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 15%;
    }
  }


  .row{
    display: flex;
    width: 100%;
    font-size: 11px;

    .col1{
      width: 50%;
      background-color:#f1f1f1;
      font-weight: 400;
      text-align: left;
      padding-left: 10px;
    }
  
    .col2{
      width: 50%;
      font-weight: 600;
      text-align:right;
      padding-right: 10px;
    }


    .span{
      margin-top: 11px;
      display: inline-flex;
    }
  }
}
