.componentSpan {
    color: white;
    font-weight: bold;
    font-family: helvetica;
}


.swingTopHeader{
    display: flex;
    max-width: 50%;
}

.legend {
    display: block;
    flex: 3;
  margin: 5px;
  border: 1px solid #445;
  padding: 3px;
   
  width: 100%;
}

.legendBox {
    background: transparent;
    width: 100%;
    height: auto;
    margin: 5px;
}