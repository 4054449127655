.mainNotificationListContainer {
  width: 180px;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 5px;
}

.options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  margin-bottom: 5px;
}
.seenDelete {
  display: flex;
}
