.alertRowContainer {
  display: flex;
  color: white;
  flex-direction: row;
  height: 6%;
  text-align: center;
  margin-top: 4%;
  border-bottom: 1px solid #fff;
  padding: 15px;
  overflow-y: auto;

  .alertRowItem {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }



  .indicateSpan {
    color: white;
    border: 1px solid #66bc46;
    border-radius: 10px;
    width: 60px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    font-family: helvetica;
  }

  .indicateSpanLess {
    color: white;
    border: 1px solid rgb(255, 34, 34);
    border-radius: 10px;
    width: 60px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    font-family: helvetica;
  }
}
