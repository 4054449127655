.alertsContainer {
  background-color: #07191d;
  //max-width: 90%;
  max-width: 100%;
  height: 620px;
  max-height: 620px;
  padding: 20px;
  border-radius: 10px;
  .alertRowContainer {
    display: flex;
    flex-direction: column;
    margin-top: 2%;
  }
  .submitBtnContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 5%;
  }
}
