.mainContainer {
  background-color: #07191d !important;
  height: 100%;
  width: 100%;
  padding: 10px;
  min-height: 300px;
  .simulationInput {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    margin-top: 2%;
    width: 100%;
    .inputContainer {
      flex-direction: column;
      color: white;
      align-items: center;
      display: flex;
    }

    .chartRow {
      display: flex;
      flex-direction: column;
      margin-top: 15%;
      align-items: center;
      .chartItem {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .resultDiv {
          width: 250px;
          display: flex;
          border-bottom: 2px solid #eee;
          padding: 5px;
          margin-top: 12%;
          justify-content: space-between;
          .resultSpan {
            color: white;
            white-space: nowrap;
            justify-content: space-between;
            font-weight: bold;
          }
        }
      }
    }
  }
  
  .inputInfoSpan {
    color: white;
    text-transform: uppercase;
    font-weight: bold;
  }
  .simulationTop {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    border-bottom: 1px solid grey;
    .simulationSpan {
      font-size: 16px;
      color: white;
      font-weight: bold;
      font-family: "helvetica";
    }
  }
  .simulateButtonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 3%;
  }

  .simulationBottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;

    .simulationResultContainer {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      margin-top: 2%;
      .simulatedResult {
        max-width: 100%;
      }
    }
  }
}

.inputStyle {
  background-color: transparent !important;
  width: 250px;
  border: none;
  border-bottom: 2px solid white;
  transition: all 1s;
  color: white;
  outline: 0 !important;

  &:hover {
    box-shadow: none;
    border-bottom: 2px solid #57b846;
    transition: all 1s;
  }
}
:global .ant-input-number-input-wrap {
  border: none;
  outline: 0 !important;
}
:global .ant-input::placeholder {
  color: white !important;
  background-color: transparent;
  font-size: 15px !important;
}

.progressCircle {
  path:nth-child(3) {
    stroke: #437d73 !important;
  }
}

:global .ant-progress-default .ant-progress-inner {
  width: 130px !important;
  height: 130px !important;
}
