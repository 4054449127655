.swingDataContainer {
  background-color: #07191d;

  max-height: 600px;
  height: 500px;
  padding: 20px;
  border-radius: 10px;
  overflow-y: scroll;
  overflow-x: hidden;

  max-width: 46%;
  .swingDataBody {
    display: flex;
    flex-direction: column;
    height: 400px;
  }
}

.containerMessage {
  display: flex;
  justify-content: center;
  text-align: center;
  max-width: 46%;
  .message {
    color: #ffffff;
    text-align: center;
  }
}
