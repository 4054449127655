.mainContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin: 5px;
}

.calendar {
  width: 250px !important;
  position: relative;
  font-size: 10px;
  color: black !important;
}

.header {
  width: 250px;
  height: 30px;
  background-color: #0B1E22;
  color: white;
  font-size: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
}

.fc .fc-daygrid-day-number{
  z-index: 4;
    padding-top: 5px;
    padding-right: 5px;
    padding-left: 0px;
    padding-bottom: 0px;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events{
  position: relative;
    min-height: 1.5em;
}