.rowContainer {
  display: flex;
  border-bottom: 1px solid #eeee;
  padding: 10px;
  
}
.rowContainerUnchecked {
  display: flex;
  border-bottom: 1px solid #eeee;
  padding: 10px;
  background-color:rgb(49, 68, 49);
}
.columnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;

  .priceSpan {
    color: white;
    border: 1px solid #66bc46;
    border-radius: 10px;
    width: 60px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-family: helvetica;
  }
  span {
    text-align: center;
    font-size: 12px;
    font-family: helvetica;
  }
}