.pendingContainer {
  background-color: #07191d;
  height: auto;
  border-radius: 10px;
  padding: 10px;

  .topContainer {
    display: flex;
    color: white;
    flex-direction: row;
    height: 6%;
    text-align: center;
    margin-top: 4%;
    border-bottom: 1px solid #fff;
    .pendentHeaderTitle {
      width: 100%;
      height: 2%;
    }
  }
  .pendingBody {
    flex-direction: column;
    height: auto; 
    align-items: center;
    color: #fff;
  }
  .frameTop {
    display: flex;
    padding: 20px;
    border-bottom: 1px solid grey;
    align-items: center;
    justify-content: space-between;
  }
}
