.pendingContainer {
  background-color: #07191d;
  max-width: 90%;
  margin-left: 2%;
  height: 620px;
  max-height: 650px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  border-radius: 10px;

  .topContainer {
    display: flex;
    color: white;
    flex-direction: row;
    height: 6%;
    text-align: center;
    margin-top: 4%;
    border-bottom: 1px solid #fff;
    .pendentHeaderTitle {
      width: 100%;
      height: 2%;
    }
  }
  .pendingBody {
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    height: 250px;
    align-items: center;
    color: #fff;
  }
  
}
