.video {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  background-size: cover;
}

.loginContainer {
  position: relative;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 30px;
  width: 50vh;
  min-width: 50vh;
  height: 100vh;
  min-height: 100vh;
  margin-left: 65%;
  overflow: hidden;
  z-index: 999;

  .inputContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    padding: 50px;
    flex-direction: column;

    .tInput {
      margin-top: 10px;
      width: 130%;
      align-items: center;
      display: flex;
    }
  }
  .buttonContainer {
    justify-content: center;
    align-items: center;
    display: flex;
    span {
      color: white;
      text-transform: uppercase;
      font-weight: 1500;
      letter-spacing: 3px;
      font-family: helvetica;
    }
  }
}
.logoContainer {
  display: flex;
  align-items: center;
  img {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    top: -50px;
  }


  .welcome {
    color: 'white';
    z-index:999;
    font-size: 50;
    position: 'fixed';
    top:'33%'; 
    left:'10%'; 
    font-family: 'helvetica';
    font-weight: "normal";
  }

  .welcomeLogo {
    color: 'white';
    z-index:999;
    font-size: 112;
    position: 'fixed';
    top:'33%'; 
    left:'10%'; 
    font-family: 'helvetica';
    font-weight: "normal";
  }

}
.title {
  text-align: center;
  color: white;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  h1 {
    color: white !important;
    font-weight: 200;
    height: 20px;
    font-size: 43px;
  }
}

.ant-input-affix-wrapper > input.ant-input {
  background-color: transparent !important;
}

.inputOverride input::placeholder {
  font-size: 25px !important;
}
.inputOverride input {
  font-size: 25px !important;
}


.logoFoot{
  width: 40%;
  left: 30%;
  bottom: 30px;
  position: absolute;
}