.firstRow {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 50px;


  .pendingOrder {
    width: 60%;
  }

  .createOrder {
    width: 60%;
  }
}

.componentSpan {
  color: white;
  font-weight: bold;
  font-family: helvetica;
}

.secondRow {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 3%;
  .activeAlerts {
    width: 50%;
  }
  .alerts {
    width: 50%;
  }
}


.thirdRow {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 3%;

  .swingData {
    width: 100%;
   padding-bottom: 50px;
  }

  .notificationComponent {
    width: 50%;
  }
}
