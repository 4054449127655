.mainContainer {
  background-color: #07191d !important;
  height: 100%;
  max-width: 100%;
  padding: 10px;
  min-height: 300px;

  .fixedTop {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    border-bottom: 1px solid grey;
  }
  .fixedSpan {
    font-size: 16px;
    color: white;
    font-weight: bold;
    font-family: "helvetica";
  }
  //top right bottom left
  
}

.percentContainer {
  padding: 20px;
  width: 100%;
}
.percent {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  .circleMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .average{
      font-size: 8px;
      color:#5d634d;
      float:top;
      margin-bottom: 50px !important;
    }
    
    .setaRight{ 
        padding-right: 13px;
        border-left: 12px solid gray; 
        border-top: 30px solid transparent;  
        border-bottom: 30px solid transparent; 
        float: right;
        border-radius: 60px;
        border-top-left-radius: 100px;
      }
      .setaLeft{ 
        padding-left: 13px;
        border-right: 12px solid gray; 
        border-top: 30px solid transparent;  
        border-bottom: 30px solid transparent; 
        float: left;
        border-radius: 60px;
        border-top-right-radius: 100px;
      }

      .contentStyle{
        height: '160px';
        color: '#fff';
        line-height: '160px';
        text-align: 'center';
        background: '#364d79';
      }

    .circleSpan {
      display: flex;
      margin-top: 5px;
      font-weight: bold;
      font-family: helvetica;
    }
  }
}
.remainingDays {
  display: flex;
}
.progress {
  display: flex;
  align-items: center;
  justify-content: center;
}
:global .ant-progress-text {
  color: white !important;
}

