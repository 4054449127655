.defaultContainer {
  height: 58px;
  // background: #000;
  border-radius: 15px;
}

.container {
  padding: 10px;
  border-radius: 15px;
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
}

.content {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;

   strong {
    font-size: 20px;
    color: #FFFFFF;
    font-family: helvetica;
  }

  .img {
    height: 32px !important;
    width: 32px !important;
    margin-right: 15px;
  }
  
}

.divider {
  width: 39px;
  height: 0px;
  left: 435px;
  top: 31px;

  border: 1px solid #FFFFFF;
  transform: rotate(90deg);
}