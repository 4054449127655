.ant-popover-inner-content {
  background: #1B1B1B;
  padding: 3px;
  border-radius: 8px;
}

.ant-popover-inner {
  background: #1B1B1B;
  padding: 0;
  border-radius: 8px; 
}
