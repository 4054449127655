.alertRow {
  display: flex;
  flex-direction: row;
  margin-top: 60px;
  .rowItem {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.resultSpan {
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  border-radius: 10px;
  font-size: 13px;
  background-color: #5fa046;
  color: #fff;
  padding: 2px;
}

.selectActual {
  border: 1px solid white;
  border-radius: 18px;
  display: flex;
}

.inputOverride {
  input {
    font-weight: bold;
    text-align: center;
  }

  .ant-input-number-handler-wrap {
    border-radius: 5px;
  }
}
