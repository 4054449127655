.cbotSliderContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  .cbotSlider {
    width: 50%;
  }
  .cbotSpanContainer {
    display: flex;
    flex-direction: column;
  }
}

.inputOverride {
  input {
    font-weight: bold;
    text-align: center;
  }

  .ant-input-number-handler-wrap {
    border-radius: 5px;
  }
}
