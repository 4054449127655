.topMonthsContainer {
  border-bottom: 1px solid gray;
  width: 95%;
  margin-left: 5%;
}
.topMonths {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px;
  .monthSpan {
    background-color: #66bc46;
    font-weight: bold;
    color: white;
    padding: 5px;
    width: 100px;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    font-family: helvetica;
    font-family: helvetica;
  }

  .monthSpanSelected {
    font-weight: bold;
    color: #5d634d;
    padding: 5px;
    width: 120px;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    font-family: helvetica;
  }
}
