.mainNotificationItemContainer {
  display: flex;
  flex-direction: column;
}

.itemRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
}

.notificationData {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  margin-left: 7px;
}

.itemColumn {
  display: flex;
  flex-direction: column;
}

.notSeen {
  display: block;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 4px;
}