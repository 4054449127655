/* .ant-input {
  background-color: transparent;
  font-size: 25px !important;
  font-weight: bold;
  color: white;
  font-family: "Bebas Neue", cursive;
  transition: all 1s;
} */

.ant-input-affix-wrapper > input.ant-input {
  background-color: transparent !important;
  color: white !important;
  font-size: 20px;
}

.ant-input:focus {
  outline: transparent !important;
  transition: all 1s;
}

.ant-input-affix-wrapper {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid white;
  transition: all 1s;
}
.ant-input-affix-wrapper:focus {
  box-shadow: none;
  border-bottom: 2px solid #57b846;
  transition: all 1s;
}
.ant-input-affix-wrapper:hover {
  box-shadow: none;
  border-bottom: 2px solid #57b846;
  transition: all 1s;
}
.ant-input-affix-wrapper-focused {
  box-shadow: none;
  transition: all 1s;
}
.wrapper-focused {
  box-shadow: none;
  -webkit-box-shadow: none;
  transition: all 1s;
}

.ant-input-suffix {
  color: white !important;
}

