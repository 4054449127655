.logoContainer {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10%;
  img {
    width: auto;
    max-height: 35px;
    justify-content: center;
    margin-top: 2%;
  }
}
.sideActionsContainer {
  padding: 20px;
  border-top: 2px solid #b5bbc1;
  border-bottom: 2px solid #b5bbc1;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.frameContainer {
  margin-top: 1%;
}

.fixedContainer {
  margin-top: 1%;
}

.simulationContainer {
  margin-top: 10px;

}.tradingViewContainer {
  margin-top: 10px;
  height: 500px;
}
.tradingViewLessContainer {
  margin-top: 10px;
}


.tradingViewMaxContainer {
  margin-top: 10px;
  width:50%;
  float: left;
  padding:10px;
}

hr {
  width: 100%;
}

.mainContainer {
  background-color: #07191d !important;
  height: 100%;
  width: 100%;
  padding: 10px;
  min-height: 300px;
  .simulationTop {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    border-bottom: 1px solid grey;
    .simulationSpan {
      font-size: 16px;
      color: white;
      font-weight: bold;
      font-family: "helvetica";
    }
  }
}

.buttonCancelTerms{
  background-color: red;
  color: white;
  text-align: center;
  left: -45%;
}
.buttonOkTerms{
  background-color: #00841d;
  color: white;
  text-align: center;
  left: -45%;
}

.modal_ant {
  width: 450px;
}