.ant-modal-content {
  background: #000;
};

.ant-modal-body {
  padding: 0;
}

.ant-modal-close-x {
  width: 32px;
  height: 32px;
  line-height: 32px;
}

.ant-modal-header {
  background: #000;
  border-bottom: none;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-modal-close {
  color: #fff;
  padding: 3px;

  :hover {
   color: #ff0000; 
  }
}

.ant-modal-title {
  color: #fff;
  text-align: center;
}