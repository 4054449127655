.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .chooseFrame {
    margin-bottom: 10px;
    margin-top: 10px;

    span {
      font-size: 12px;
      font-weight: bold;
      color: black;
      font-family: helvetica;
    }

  }
  p {
    font-size: 14px;
    color: black;
    font-family: helvetica;
  }
  
  .frame {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    
    button {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 3px;
      margin-left: 8px;
      border-radius: 50px;

      
      &:hover {
        opacity: 0.7;
        transition: 0.2s;
        cursor: pointer;  
      }
      
      img {
        height: 50px;
        width: 50px;
        }
    }

  }

  .selectInput {
    width: 190px;
    height: 30px;
    border-radius: 5px;
    font-family: helvetica;
    background-color: #fff;
    border-width: 1px;

    &:hover {
      cursor: pointer;
    }
    
    .optionSelected {
      width: auto;
      padding: 10px;
      font-size: 17px;
      border-radius: 3px;
      font-family: helvetica;
    }
  }
}