.mainContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #f1f1f1;
  width: 100%;
  height: 100%;
  min-width: 250px;
}
.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(206, 206, 206);
  padding: 6px;
  width: 100%;
  flex-direction: column;

  cursor: pointer;
  img {
    height: 15px;
  }
  .stockTimeSpan {
    color: black;
    font-size: 12px;
    font-weight: 700;
    font-family: helvetica;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .stockTimeDays {
    font-size: 10px;
    color: black;
    font-weight: 400;
    font-family: helvetica;
  }
}

.stockTimeClose {
  background-color: #f1f1f1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding:1px;
  
  .info {
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    width: 100%;

    .infoContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 12px;
    }
    .infotxt {
      display: flex;
      justify-content: left;
      text-align: justify;
      align-items: center;
      padding: 10px;
      width: 250px;


      .cbot {
        font-size: 12px;
        background-color: #ea6f24;
        border-radius: 20px;
        color: white;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0px;
        padding-right: 10px;
        padding-top:20px;
        padding-bottom: 12px;
        padding-left: 10px;
        text-align: center;
        width: 33px;
        display: flex;
        font-family: helvetica;
        height: 100px;
      }
      .basis {
        font-size: 12px;
        background-color: #0072bc;
        border-radius: 20px;
        color: white;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0px;
        padding-right: 14px;
        padding-top:10px;
        padding-bottom: 12px;
        padding-left: 12px;
        text-align: center;
        width: 33px;
        display: flex;
        font-family: helvetica;
        height: 100px;
      }
      .dolar {
        font-size: 12px;
        background-color: #66bc46;
        border-radius: 20px;
        color: white;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0px;
        padding-right: 14px;
        padding-top:10px;
        padding-bottom: 12px;
        padding-left: 12px;
        text-align: center;
        width: 33px;
        display: flex;
        font-family: helvetica;
        height: 100px;
      }

    }
    
  }
}

.spanFont{
  font-family: helvetica;
  font-weight: 550;
  line-height: 20px;
}